export const demoAdminAccess = [
    "/",
    "/faq",
    "/products",
    "/products/detail",
    "/gtins/detail",
    "/batches/detail",
    "/transactions",
    "/transactions/brandprotection",
    "/feedback",
    "/campaigns",
    "/new-campaign",
    "/provenance",
    "/provenance/detail",
    "/provenance/templates",
    "/provenance/template-details",
    "/personas",
    "/personas/template",
    "/personas/table",
    "/personas/new-person",
    "/create-qr-codes",
    "/sap-analytics",
    "/master-dispatch",
    "/report",
    "/analytics",
    "/users-table",
    "/new-user",
    "/diy-work-flow",
    "/manufacturer",
    "/manufacturer/social-handles",
    "/new-assortment-range",
    "/assortment-ranges",
    "/admin-level-qr",
    "/assortment-batches",
];
